import React, {useState, useEffect} from "react";
import ExploreCard from "./exploreCard";
import { atom, useAtom } from 'jotai';
import { searchValueAtom, SearchBox, isSearchingAtom } from '../header/index';
import { infoSidebarOpenAtom, infoSidebarOpenDelayAtom } from '../../sidebar/infoSidebar/index';
import "./exploreSection.css";
import { useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { useScrollCloseToBottom, useScrollAtBottom } from '../../../utils/scroll';
import { FaCalendarAlt, FaUserAlt } from "react-icons/fa";
import { format, toDate } from 'date-fns'

import ReactGA from "react-ga"

const ExploreSection = ({ restaurants, collectionName }) => {
  return (
    <div className="max-width explore-section">
      <div className="collection-title">{collectionName}</div>
      <div className="explore-grid">
        {restaurants.map((restaurant, i) => (
          <ExploreCard restaurant={restaurant} i={i} />
        ))}
      </div>
    </div>
  );
};

export const currentPostAtom = atom(null);
export const currentLocationAtom = atom(null);
export const allPostsAtom = atom([]);

export const ExploreSectionv2 = ({ list, collectionName }) => {
  const [currentLocation, setCurrentLocation] = useAtom(currentLocationAtom);
  const [searchValue, setSearchValue] = useAtom(searchValueAtom);
  const [isSearching, setIsSearching] = useAtom(isSearchingAtom);
  const [isInfoSidebarOpen, setIsInfoSidebarOpen] = useAtom(infoSidebarOpenAtom);
  const [isInfoSidebarOpenDelay, setIsInfoSidebarOpenDelay] = useAtom(infoSidebarOpenDelayAtom);
  const [currentPost, setCurrentPost] = useAtom(currentPostAtom);
  const [allPosts, setAllPosts] = useAtom(allPostsAtom);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [sliceCount, setSliceCount] = useState(8);

  const { influencerId } = useParams();
  const { height, width } = useWindowDimensions();
  

  let navigate = useNavigate();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  const openDetail = (item) => {
    if(isInfoSidebarOpenDelay && width < 600) {
      return;
    }
    const id = item.eventId;
    if(id == null) {return;}

    ReactGA.event({
      category: "posts",
      action: "navigate",
      label: "navigate",
      value: influencerId
    })
    if(influencerId) {
      navigate(`/${influencerId}/${id}`);
    }else {
      navigate(`/posts/${id}`);
    }
  }
  const sortByDateAndDistance = (a,b) => {
    const pointA = getDistance(a, currentLocation) > 100 ? 100 : 1;
    const pointB = getDistance(b, currentLocation)> 100 ? 100 : 1;
    
    let point = new Date(a.date)*pointA - new Date(b.date)*pointB;
    
    return point;
  }

  const filterOnSearchValue = (item) => {
    const values = searchValue.toLowerCase().split(" ");
    for(const value of values) {
      if (!item.name?.toLowerCase().includes(value)) {
        return false;
      }
    }
    
    return true;
  };

  const filterOnActive = (item) => {
    if(item.date==null) {return true}

    if(isExpired(item)) {
      return false;
    }
    
    return true;
  };

  const filterOnDistance = (item) => {
    if(window.location.href.includes("localhost")) {
      return true;
    }

    const distance = getDistance(item, currentLocation);
    if(distance < 30) {
      return true;
    }

    return false
  }

  const getFiltedPosts = () => {
    return list
    .sort(sortByDateAndDistance)
    .filter(filterOnSearchValue)
    .filter(filterOnActive)
    // .filter(filterOnDistance)
    .slice(0, sliceCount);
  }

  const isCurrentPost = (item) => {
    if(item == null || currentPost==null) {
      return false;
    }
    return item.eventId===currentPost?.eventId;
  }

  const isExpired = (item) => {
    if(item.date==null) {return false}
    
    const diffTime = new Date() - new Date(item.date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    if(diffDays > 1) { return true; }
    return false;
  }

  useEffect(() => {
    if(isSearching === true){
      setTimeout(() => {
        setIsSearching((value) => {
          if(value===true) {
            return false;
          }
          return true;
        })
      }, 2000);
    }
  }, [isSearching])

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
      setCurrentLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  useEffect(() => {
    setAllPosts(list);
  }, []);

  useScrollCloseToBottom(() => updateSliceCount());
  useScrollAtBottom(() => updateSliceCount());

  const updateSliceCount = () => {
    if( sliceCount < list.length ) {
      setSliceCount((count) => { return count + 4;})
    }
  }

  const placeCover = (item, index) => (
    <div key={index}>
      <div className="place-cover" onClick={() => openDetail(item)}>
        <img
          src={item.logo}
          alt={'Loading'}
          className="collection-image"
        />
        {isExpired(item) ? (
          <div className="inactive-bg"></div>
        ) : (
          <div className="gradient-bg"></div>
        )}
        
        <div className="place-card-title" style={{
          color: isCurrentPost(item) ? "rgb(193, 57, 57)" : null
        }}>
          {item.type==='user' ? (
            <FaUserAlt />
          ): (
            <i className="fi fi-rr-marker absolute-center"></i>
          )}
          
          <div >{item.name}</div>
        </div>
        {placeCardSubtitle(item, currentLocation)}
      </div>
    </div>
  )

  return (
    <div className="collection-wrapper">
      <div className="max-width explore-section">
        {/* <div className="collection-title">{collectionName}</div> */}
        <div className="place-search-box">
          <SearchBox />
          
        </div>
        <div className="place-search-box">
          <div>Contribute at &nbsp;
            <a href="https://www.facebook.com/cosplayvn/posts/pfbid02bttZVH1t5HGRiNTRVAxqGCPvmTynnWnDCXFXi9TXFd67V3ai4ErLCkPok4Yh92iGl" target="_blank" rel="noreferrer">
              Cosplay VN
            </a> 
          </div>
        </div>
        
        <div className="tiktok-grid">
          {getFiltedPosts()
            .map((item, index) => placeCover(item, index))
          }
        </div>
      </div>
    </div>
  );
};

export const getDistance = (item, currentLocation) => {
  if(currentLocation == null) return -1;
  if(item?.location == null) return -2;

  const distance = getDistanceFromLatLonInKm(item.location.lat, item.location.lng, 
    currentLocation.lat, currentLocation.lng);

  return distance;
};

export const getDistanceFormatted = (item, currentLocation) => {
  let distance = getDistance(item, currentLocation);
  if(distance === -2) {
    return "#Nearby";
  }

  distance = distance.toFixed(1);

  if(distance > 0) {
    return distance;
  }

  return "???";
};
export function placeCardSubtitle(item, currentLocation) {
  const isFaraway = (item) => {
    const distance = getDistance(item, currentLocation);
    if(distance > 100) {
      return true;
    }

    return false;
  }
  const isDaycoming = (item) => {
    const diffTime = new Date(new Date(item.date) - new Date());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    if(diffDays < 7) {
      return true;
    }

    return false;
  }


  return <div className="place-card-subtitle">
    {item.date && (<>
      <FaCalendarAlt />
      <div className={isDaycoming(item) ? "daycomming" : ""}>{item.date && format(item.date, 'dd/MM')}</div>
    </>)}

    <i class="fi fi-rr-taxi icon-distance taxi-icon"></i>
    <div className={isFaraway(item) ? "place-distance place-faraway" : "place-distance"}> {getDistanceFormatted(item, currentLocation)} km</div>

    {
      // (item.money && <>
      //   <i class="fi fi-rr-money-bill-wave-alt absolute-center"></i>
      //   <div className="place-distance"> {item.money.slice(0,12)}</div>
      // </>)
    }
  </div>;
}

function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLon = deg2rad(lon2-lon1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI/180)
}

export default ExploreSection;
