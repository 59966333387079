import React, { useState } from "react";
import Footer from "../../components/common/footer";

import Header from "../../components/common/header";
import TabOptions from "../../components/common/tabOptions";
import Delivery from "../../components/delivery";
import DiningOut from "../../components/diningOut";
import Nightlife from "../../components/nightlife";
import InfoSidebar from "../../components/sidebar/infoSidebar";
import "./home.css";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { atom, useAtom } from "jotai";

export const activeTabAtom = atom("Events");

const HomePage = () => {
  const [activeTab, setActiveTab] = useAtom(activeTabAtom);
  const main = () => (
    <>
      <TabOptions />
      {getCorrectScreen(activeTab)}
      <InfoSidebar />
      <Footer />
    </>
  );
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={main()} />
        <Route path="/:influencerId" element={main()} />
        <Route path="/:influencerId/:postId" element={main()} />
        <Route path="/posts/:postId" element={main()} />
      </Routes>
  </BrowserRouter>
  );
};

const getCorrectScreen = (tab) => {
  switch (tab) {
    case "Coser":
      return <Delivery />;

    case "Events":
      return <DiningOut />;

    case "Nightlife":
      return <Nightlife />;

    default:
      return <Delivery />;
  }
};
export default HomePage;
