import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div className="absolute-center max-width footer">
      <h4>Join our community 🔥 at &nbsp;
        <a href="https://www.facebook.com/groups/1285335755591432" target="_blank" rel="noreferrer">
          Gofescosplay
        </a> 
      </h4>
    </div>
  );
};

export default Footer;
