import jsonPosts from './posts.json';

export const getEvents = (infludencerId: string) => {
	if(infludencerId == null) {
		// return require(`./posts.json`);
		infludencerId = "cosplayvn";
	}

	return require(`./sources/${infludencerId}.json`);
};
