import React from 'react';

import HomePage from './pages/home';
import ReactGA from "react-ga"

const TRACKING_ID = "UA-134955077-3";
ReactGA.initialize(TRACKING_ID);

const App = () => {
  return <HomePage />;
};

export default App;
