import React from "react";
import { restaurants } from "../../data/restaurants";
import ExploreSection from "../common/exploreSection";

import Filters from "../common/filters";

import "./delivery.css";
import DeliveryColletions from "./deliveryCollections";
import TopBrands from "./topBrands";
import { ExploreSectionv2 } from '../common/exploreSection';
import { posts } from '../../data/posts.ts';

const deliveryFilters = [
  {
    id: 1,
    icon: <i className="fi fi-rr-settings-sliders absolute-center"></i>,
    title: "Filters",
  },
  {
    id: 2,
    title: "Rating: 4.0+",
  },
  {
    id: 3,
    title: "Safe and Hygienic",
  },
  {
    id: 4,
    title: "Pure Veg",
  },
  {
    id: 5,
    title: "Delivery Time",
    icon: <i className="fi fi-rr-apps-sort absolute-center"></i>,
  },
  {
    id: 6,
    title: "Great Offers",
  },
];

const restaurantsList = restaurants;

const Delivery = () => {
  const posts = [{
    "postUrl": "comming-soon",
    "logo": "/assets/coming-soon.png",
    "name": "Coser",
    "address": "#Gofescosplay #Delivery",
    "money": null,
    "location":null,
    "date": null,
    "link": "https://www.facebook.com/JVF.VN/",
    "eventId": null,
    "type": "user"
  }]
  return (
    <div>
      <ExploreSectionv2
        list={posts}
        collectionName="Dine-Out Restaurants in Hà Nội"
      />
      {/* <DeliveryColletions />
      <TopBrands />
      <ExploreSection
        restaurants={restaurantsList}
        collectionName="Delivery Restaurants in Bangalore"
      /> */}
    </div>
  );
};

export default Delivery;
