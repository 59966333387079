export const nightLife = [
  {
    type: "restaurant",
    info: {
      resId: 19463530,
      name: "The Beer Cafe Biggie",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/0/19463530/575a8965aeb1ff7d1b926438eee25830_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/0/19463530/575a8965aeb1ff7d1b926438eee25830_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: "https://b.zmtcdn.com/data/pictures/0/19463530/607835b4835149b4c2cb544b5420b9eb_o2_featured_v2.jpeg",
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "4.0",
        rating_text: "4.0",
        rating_subtitle: "Very Good",
        rating_color: "5BA829",
        votes: "126",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "4.0",
            reviewCount: "125",
            reviewTextSmall: "125 Reviews",
            subtext: "125 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "4.0",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "600",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "",
            reviewCount: "1",
            reviewTextSmall: "1 Reviews",
            subtext: "Does not offer Delivery",
            color: "",
            ratingV2: "-",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "grey",
              tint: "500",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹1,700 for two",
      },
      cfo: {
        text: "₹500 for one",
      },
      locality: {
        name: "Hazratganj, Lucknow",
        address:
          "Second Floor, Rohit House, Shahnajaf Road, Opposite Sahara Mall, Hazratganj, Lucknow",
        localityUrl: "lucknow/hazratganj-restaurants",
      },
      timing: {
        text: "Closes in 1 hour 26 minutes",
        color: "#e5521f",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/italian/",
          name: "Italian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiODJcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/pizza/",
          name: "Pizza",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/chinese/",
          name: "Chinese",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/north-indian/",
          name: "North Indian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNjZcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/lebanese/",
          name: "Lebanese",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjcwXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/beverages/",
          name: "Beverages",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹1,700 for two",
      },
    },
    order: [],
    gold: {
      text: "Pro - Get 20% off",
    },
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/the-beer-cafe-biggie-hazratganj/info",
      clickActionDeeplink: "",
    },
    distance: "1.1 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"19463530","element_type":"listing","rank":13}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [
      {
        type: "booking_cta",
        text: "Book a Table",
        clickUrl: "/lucknow/the-beer-cafe-biggie-hazratganj/book",
      },
    ],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [
      {
        image: {
          url: "https://b.zmtcdn.com/MaxSafety/DiningBadge/a9e3f60f5ed05ff69a6bfa5eb87b442e1611745067.png",
          aspect_ratio: 2.66666666667,
        },
        text: "Follows all Max Safety measures for a safe dining experience",
      },
    ],
  },
  {
    type: "restaurant",
    info: {
      resId: 19851199,
      name: "Black Brew House",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/9/19851199/8e3d34b52ad67c45b0ed6bb43a376bc2_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/9/19851199/8e3d34b52ad67c45b0ed6bb43a376bc2_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: false,
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "3.3",
        rating_text: "NEW",
        rating_subtitle: "Average",
        rating_color: "CDD614",
        votes: "14",
        subtext: "REVIEWS",
        is_new: true,
      },
      ratingNew: {
        newlyOpenedObj: {
          text: "Newly Opened",
          color: "#FFFFFF",
          bgColor: "#F4A266",
          bgColorV2: {
            type: "green",
            tint: "100",
          },
          subtitle: "OUTLET",
          ratingV2: "New",
        },
        suspiciousReviewObj: null,
        ratings: [],
      },
      cft: {
        text: "₹1,200 for two",
      },
      cfo: {
        text: "₹500 for one",
      },
      locality: {
        name: "Gomti Nagar, Lucknow",
        address:
          "15th & 16th Floor, Summit Building, Vibhuti Khand, Gomti Nagar, Lucknow",
        localityUrl: "lucknow/gomti-nagar-restaurants",
      },
      timing: {
        text: "Closes in 27 minutes",
        color: "#ab000d",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/north-indian/",
          name: "North Indian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMTA2NFwiXX0iXQ%3D%3D",
          url: "https://www.zomato.com/lucknow/restaurants/pasta/",
          name: "Pasta",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiODJcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/pizza/",
          name: "Pizza",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNDBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/fast-food/",
          name: "Fast Food",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹1,200 for two",
      },
    },
    order: [],
    gold: [],
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/black-brew-house-gomti-nagar/info",
      clickActionDeeplink: "",
    },
    distance: "6.1 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"19851199","element_type":"listing","rank":14}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [
      {
        type: "booking_cta",
        text: "Book a Table",
        clickUrl: "/lucknow/black-brew-house-gomti-nagar/book",
      },
    ],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },
  {
    type: "restaurant",
    info: {
      resId: 19058715,
      name: "Be The Beer Season 2",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/5/19058715/09b91edb2219ae78dcfbcfa7cbd3460a_o2_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/chains/7/801767/6382b981cc69b0b7ccda9c22aee6c9c0_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: "https://b.zmtcdn.com/data/pictures/5/19058715/09b91edb2219ae78dcfbcfa7cbd3460a_o2_featured_v2.jpg",
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "4.1",
        rating_text: "4.1",
        rating_subtitle: "Very Good",
        rating_color: "5BA829",
        votes: "258",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "4.1",
            reviewCount: "247",
            reviewTextSmall: "247 Reviews",
            subtext: "247 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "4.1",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "700",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "",
            reviewCount: "11",
            reviewTextSmall: "11 Reviews",
            subtext: "Does not offer Delivery",
            color: "",
            ratingV2: "-",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "grey",
              tint: "500",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹1,200 for two",
      },
      cfo: {
        text: "₹500 for one",
      },
      locality: {
        name: "Gomti Nagar, Lucknow",
        address:
          "19, 20, Rohtas Presidential Arcade, Vibhuti Khand, Gomti Nagar, Lucknow",
        localityUrl: "lucknow/gomti-nagar-restaurants",
      },
      timing: {
        text: "Closes in 1 hour 26 minutes",
        color: "#e5521f",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/north-indian/",
          name: "North Indian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/chinese/",
          name: "Chinese",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/italian/",
          name: "Italian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNzNcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/mexican/",
          name: "Mexican",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹1,200 for two",
      },
    },
    order: [],
    gold: {
      text: "Pro - Get 20% off",
    },
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/be-the-beer-season-2-gomti-nagar/info",
      clickActionDeeplink: "",
    },
    distance: "5.7 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"19058715","element_type":"listing","rank":15}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [
      {
        type: "booking_cta",
        text: "Book a Table",
        clickUrl: "/lucknow/be-the-beer-season-2-gomti-nagar/book",
      },
      {
        type: "chain_cta",
        text: "View all outlets",
        clickUrl: "/lucknow/restaurants/be-the-beer-1?category=3",
      },
    ],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [
      {
        image: {
          url: "https://b.zmtcdn.com/MaxSafety/DiningBadge/a9e3f60f5ed05ff69a6bfa5eb87b442e1611745067.png",
          aspect_ratio: 2.66666666667,
        },
        text: "Follows all Max Safety measures for a safe dining experience",
      },
    ],
  },
  {
    type: "restaurant",
    info: {
      resId: 801203,
      name: "On High - Hotel Silvete",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/chains/3/801203/a473aec343876d74d7eff94322efc877_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/chains/3/801203/a473aec343876d74d7eff94322efc877_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: "https://b.zmtcdn.com/data/pictures/3/801203/df51bd1dff3997ad6f9a0de0365aeb0c_o2_featured_v2.jpg",
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "4.0",
        rating_text: "4.0",
        rating_subtitle: "Very Good",
        rating_color: "5BA829",
        votes: "5,783",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "4.0",
            reviewCount: "272",
            reviewTextSmall: "272 Reviews",
            subtext: "272 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "4.0",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "600",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "4.0",
            reviewCount: "5,511",
            reviewTextSmall: "5,511 Reviews",
            subtext: "5,511 Delivery Reviews",
            color: "#E23744",
            ratingV2: "4.0",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "green",
              tint: "600",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹1,100 for two",
      },
      cfo: {
        text: "₹500 for one",
      },
      locality: {
        name: "Hazratganj, Lucknow",
        address: "Hotel Silvete, 4 A, Rana Pratap Marg, Hazratganj, Lucknow",
        localityUrl: "lucknow/hazratganj-restaurants",
      },
      timing: {
        text: "Opens tomorrow at 7am",
        color: "#ab000d",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/north-indian/",
          name: "North Indian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/chinese/",
          name: "Chinese",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNDBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/fast-food/",
          name: "Fast Food",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNzVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/mughlai/",
          name: "Mughlai",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjI3XCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/bar-food/",
          name: "Bar Food",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiOTk4XCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/salad/",
          name: "Salad",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjcwXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/beverages/",
          name: "Beverages",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹1,100 for two",
      },
    },
    order: [],
    gold: {
      text: "Pro - Get 20% off",
    },
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/on-high-hotel-silvete-hazratganj/info",
      clickActionDeeplink: "",
    },
    distance: "1 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"801203","element_type":"listing","rank":16}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [
      {
        image: {
          url: "https://b.zmtcdn.com/MaxSafety/DiningBadge/a9e3f60f5ed05ff69a6bfa5eb87b442e1611745067.png",
          aspect_ratio: 2.66666666667,
        },
        text: "Follows all Max Safety measures for a safe dining experience",
      },
    ],
  },
  {
    type: "restaurant",
    info: {
      resId: 801116,
      name: "Jannat-Dayal Paradise",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/6/801116/78ac89e39e31b18b6209d5fce93a6d02_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/6/801116/78ac89e39e31b18b6209d5fce93a6d02_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: "https://b.zmtcdn.com/data/pictures/6/801116/aca44fa356685477ea737b32cf71006f_o2_featured_v2.jpg",
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "3.3",
        rating_text: "3.3",
        rating_subtitle: "Average",
        rating_color: "CDD614",
        votes: "773",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "4.0",
            reviewCount: "742",
            reviewTextSmall: "742 Reviews",
            subtext: "742 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "4.0",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "600",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "2.6",
            reviewCount: "31",
            reviewTextSmall: "31 Reviews",
            subtext: "31 Delivery Reviews",
            color: "#E23744",
            ratingV2: "2.6",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "yellow",
              tint: "600",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹1,200 for two",
      },
      cfo: {
        text: "₹500 for one",
      },
      locality: {
        name: "Hotel Dayal Paradise, Gomti Nagar, Lucknow",
        address: "CF-1, CF-2, Vipul Khand-5, Gomti Nagar, Lucknow",
        localityUrl: "lucknow/restaurants/in/hotel-dayal-paradise-gomti-nagar",
      },
      timing: {
        text: "Opens tomorrow at 1pm",
        color: "#ab000d",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/italian/",
          name: "Italian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMzVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/continental/",
          name: "Continental",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjcxXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/finger-food/",
          name: "Finger Food",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹1,200 for two",
      },
    },
    order: [],
    gold: [],
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/jannat-dayal-paradise-gomti-nagar/info",
      clickActionDeeplink: "",
    },
    distance: "4.7 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"801116","element_type":"listing","rank":17}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [
      {
        type: "booking_cta",
        text: "Book a Table",
        clickUrl: "/lucknow/jannat-dayal-paradise-gomti-nagar/book",
      },
    ],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [
      {
        image: {
          url: "https://b.zmtcdn.com/MaxSafety/DiningBadge/a9e3f60f5ed05ff69a6bfa5eb87b442e1611745067.png",
          aspect_ratio: 2.66666666667,
        },
        text: "Follows all Max Safety measures for a safe dining experience",
      },
    ],
  },
  {
    type: "restaurant",
    info: {
      resId: 19844315,
      name: "BohoBar",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/5/19844315/fa8ff31f85c4a807759e004c7b9ae59f_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/5/19844315/fa8ff31f85c4a807759e004c7b9ae59f_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: false,
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "2.5",
        rating_text: "NEW",
        rating_subtitle: "Average",
        rating_color: "FFBA00",
        votes: "13",
        subtext: "REVIEWS",
        is_new: true,
      },
      ratingNew: {
        newlyOpenedObj: {
          text: "Newly Opened",
          color: "#FFFFFF",
          bgColor: "#F4A266",
          bgColorV2: {
            type: "green",
            tint: "100",
          },
          subtitle: "OUTLET",
          ratingV2: "New",
        },
        suspiciousReviewObj: null,
        ratings: [],
      },
      cft: {
        text: "₹1,100 for two",
      },
      cfo: {
        text: "₹500 for one",
      },
      locality: {
        name: "Gomti Nagar, Lucknow",
        address:
          "G 21, Ground Floor, Levana Cyber Heights, Vibhuti Khand, Gomti Nagar, Lucknow",
        localityUrl: "lucknow/gomti-nagar-restaurants",
      },
      timing: {
        text: "Closes in 27 minutes",
        color: "#ab000d",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiM1wiXX0iXQ%3D%3D",
          url: "https://www.zomato.com/lucknow/restaurants/asian/",
          name: "Asian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/north-indian/",
          name: "North Indian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/italian/",
          name: "Italian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMzVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/continental/",
          name: "Continental",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiOTVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/thai/",
          name: "Thai",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjcwXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/beverages/",
          name: "Beverages",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹1,100 for two",
      },
    },
    order: [],
    gold: [],
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/bohobar-gomti-nagar/info",
      clickActionDeeplink: "",
    },
    distance: "6 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"19844315","element_type":"listing","rank":18}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },
  {
    type: "restaurant",
    info: {
      resId: 801247,
      name: "Royal Cafe",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/7/801247/114c3e1f87e06d7ae8410867a6693f6f_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/7/801247/114c3e1f87e06d7ae8410867a6693f6f_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: "https://b.zmtcdn.com/data/pictures/7/801247/5b36442e21fa8b1e53a5f5788d552128_o2_featured_v2.jpg",
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "4.1",
        rating_text: "4.1",
        rating_subtitle: "Very Good",
        rating_color: "5BA829",
        votes: "1,496",
        subtext: "REVIEW",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "4.2",
            reviewCount: "897",
            reviewTextSmall: "897 Reviews",
            subtext: "897 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "4.2",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "700",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "3.9",
            reviewCount: "599",
            reviewTextSmall: "599 Reviews",
            subtext: "599 Delivery Reviews",
            color: "#E23744",
            ratingV2: "3.9",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "green",
              tint: "600",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹1,500 for two",
      },
      cfo: {
        text: "₹500 for one",
      },
      locality: {
        name: "Royal Inn, Hazratganj, Lucknow",
        address: "9/7, Shahnajaf Road, Hazratganj, Lucknow",
        localityUrl: "lucknow/restaurants/in/royal-inn-hazratganj",
      },
      timing: {
        text: "Closes in 27 minutes",
        color: "#ab000d",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiODJcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/pizza/",
          name: "Pizza",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/north-indian/",
          name: "North Indian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNzVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/mughlai/",
          name: "Mughlai",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMTAwXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/desserts/",
          name: "Desserts",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/chinese/",
          name: "Chinese",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiN1wiXX0iXQ%3D%3D",
          url: "https://www.zomato.com/lucknow/restaurants/biryani/",
          name: "Biryani",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjcwXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/beverages/",
          name: "Beverages",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹1,500 for two",
      },
    },
    order: [],
    gold: {
      text: "Pro - Get 10% off",
    },
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/royal-cafe-5-hazratganj/info",
      clickActionDeeplink: "",
    },
    distance: "1.1 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"801247","element_type":"listing","rank":19}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },
  {
    type: "restaurant",
    info: {
      resId: 19870055,
      name: "Sky Glass Brewing Co.",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/5/19870055/11028dcc5011e661b28be4a2c36bb911_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/5/19870055/11028dcc5011e661b28be4a2c36bb911_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: false,
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "3.4",
        rating_text: "NEW",
        rating_subtitle: "Average",
        rating_color: "CDD614",
        votes: "12",
        subtext: "REVIEWS",
        is_new: true,
      },
      ratingNew: {
        newlyOpenedObj: {
          text: "Newly Opened",
          color: "#FFFFFF",
          bgColor: "#F4A266",
          bgColorV2: {
            type: "green",
            tint: "100",
          },
          subtitle: "OUTLET",
          ratingV2: "New",
        },
        suspiciousReviewObj: null,
        ratings: [],
      },
      cft: {
        text: "₹1,500 for two",
      },
      cfo: {
        text: "₹500 for one",
      },
      locality: {
        name: "Gomti Nagar, Lucknow",
        address: "S38, 2nd Floor, Phoenix Palassio Mall, Gomti Nagar, Lucknow",
        localityUrl: "lucknow/gomti-nagar-restaurants",
      },
      timing: {
        text: "Closes in 27 minutes",
        color: "#ab000d",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/north-indian/",
          name: "North Indian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/italian/",
          name: "Italian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNDBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/fast-food/",
          name: "Fast Food",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMTAwXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/desserts/",
          name: "Desserts",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjcwXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/beverages/",
          name: "Beverages",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹1,500 for two",
      },
    },
    order: [],
    gold: [],
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/sky-glass-brewing-co-gomti-nagar/info",
      clickActionDeeplink: "",
    },
    distance: "5.2 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"19870055","element_type":"listing","rank":20}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },
  {
    type: "restaurant",
    info: {
      resId: 801640,
      name: "Underdoggs Bar & Grill",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/0/801640/a57b5379c70a5b7dec89df436c6f152d_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/0/801640/a57b5379c70a5b7dec89df436c6f152d_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: "https://b.zmtcdn.com/data/pictures/0/801640/08a148ec865551e4aa483107d4919ffa_o2_featured_v2.jpg",
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "4.3",
        rating_text: "4.3",
        rating_subtitle: "Very Good",
        rating_color: "5BA829",
        votes: "785",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "4.3",
            reviewCount: "771",
            reviewTextSmall: "771 Reviews",
            subtext: "771 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "4.3",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "700",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "",
            reviewCount: "14",
            reviewTextSmall: "14 Reviews",
            subtext: "Does not offer Delivery",
            color: "",
            ratingV2: "-",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "grey",
              tint: "500",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹1,300 for two",
      },
      cfo: {
        text: "₹500 for one",
      },
      locality: {
        name: "City Mall, Gomti Nagar, Lucknow",
        address: "Floor 4, City Mall, Vipul Khand 4, Gomti Nagar, Lucknow",
        localityUrl: "lucknow/restaurants/in/city-mall-gomti-nagar",
      },
      timing: {
        text: "Closes in 1 hour 26 minutes",
        color: "#e5521f",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNzBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/mediterranean/",
          name: "Mediterranean",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/italian/",
          name: "Italian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMVwiXX0iXQ%3D%3D",
          url: "https://www.zomato.com/lucknow/restaurants/american/",
          name: "American",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹1,300 for two",
      },
    },
    order: [],
    gold: [],
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/underdoggs-bar-grill-gomti-nagar/info",
      clickActionDeeplink: "",
    },
    distance: "4.3 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"801640","element_type":"listing","rank":21}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },
  {
    type: "restaurant",
    info: {
      resId: 18625751,
      name: "Elev8 Book Cafe - Hotel Levana Suites",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/1/18625751/98da140e7e6ca0f91c0581a52b6898e2_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/1/18625751/98da140e7e6ca0f91c0581a52b6898e2_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: "https://b.zmtcdn.com/data/pictures/1/18625751/1c21115584717da167207f31f3f1e99c_o2_featured_v2.jpg",
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "4.0",
        rating_text: "4.0",
        rating_subtitle: "Very Good",
        rating_color: "5BA829",
        votes: "291",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "4.0",
            reviewCount: "288",
            reviewTextSmall: "288 Reviews",
            subtext: "288 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "4.0",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "600",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "",
            reviewCount: "3",
            reviewTextSmall: "3 Reviews",
            subtext: "Does not offer Delivery",
            color: "",
            ratingV2: "-",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "grey",
              tint: "500",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹1,200 for two",
      },
      cfo: {
        text: "₹500 for one",
      },
      locality: {
        name: "Hazratganj, Lucknow",
        address: "18, Madan Mohan Malviya Marg, Hazratganj, Lucknow",
        localityUrl: "lucknow/hazratganj-restaurants",
      },
      timing: {
        text: "Closes in 1 hour 26 minutes",
        color: "#e5521f",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNDBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/fast-food/",
          name: "Fast Food",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjcxXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/finger-food/",
          name: "Finger Food",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/chinese/",
          name: "Chinese",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/north-indian/",
          name: "North Indian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjcwXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/beverages/",
          name: "Beverages",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹1,200 for two",
      },
    },
    order: [],
    gold: [],
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/elev8-book-cafe-hotel-levana-suites-hazratganj/info",
      clickActionDeeplink: "",
    },
    distance: "993 m",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"18625751","element_type":"listing","rank":22}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },
  {
    type: "restaurant",
    info: {
      resId: 18868910,
      name: "Bombay Brasserie",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/0/18868910/bcb34c8eec95b3d3bff8c0e7338cc949_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/0/18868910/bcb34c8eec95b3d3bff8c0e7338cc949_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: "https://b.zmtcdn.com/data/pictures/0/18868910/c1b8dd3d95aea1f67340b5aa2c490979_o2_featured_v2.jpg",
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "4.4",
        rating_text: "4.4",
        rating_subtitle: "Very Good",
        rating_color: "5BA829",
        votes: "716",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "4.6",
            reviewCount: "605",
            reviewTextSmall: "605 Reviews",
            subtext: "605 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "4.6",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "800",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "4.1",
            reviewCount: "111",
            reviewTextSmall: "111 Reviews",
            subtext: "111 Delivery Reviews",
            color: "#E23744",
            ratingV2: "4.1",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "green",
              tint: "700",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹1,500 for two",
      },
      cfo: {
        text: "₹500 for one",
      },
      locality: {
        name: "Gomti Nagar, Lucknow",
        address:
          "003, Ground Floor, Rohtas Summit, Vibhuti Khand, Gomti Nagar, Lucknow",
        localityUrl: "lucknow/gomti-nagar-restaurants",
      },
      timing: {
        text: "",
        color: "",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMTAxOFwiXX0iXQ%3D%3D",
          url: "https://www.zomato.com/lucknow/restaurants/modern-indian/",
          name: "Modern Indian",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹1,500 for two",
      },
    },
    order: [],
    gold: [],
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/bombay-brasserie-gomti-nagar/info",
      clickActionDeeplink: "",
    },
    distance: "6.1 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"18868910","element_type":"listing","rank":23}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [
      {
        type: "booking_cta",
        text: "Book a Table",
        clickUrl: "/lucknow/bombay-brasserie-gomti-nagar/book",
      },
    ],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },
  {
    type: "restaurant",
    info: {
      resId: 18159999,
      name: "Sky Bar - Renaissance Lucknow Hotel",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/9/18159999/33b00192ce3104a2c6bba27710055025_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/9/18159999/33b00192ce3104a2c6bba27710055025_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: false,
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "4.2",
        rating_text: "4.2",
        rating_subtitle: "Very Good",
        rating_color: "5BA829",
        votes: "466",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "4.2",
            reviewCount: "466",
            reviewTextSmall: "466 Reviews",
            subtext: "466 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "4.2",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "700",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "",
            reviewCount: "0",
            reviewTextSmall: "0 Reviews",
            subtext: "Does not offer Delivery",
            color: "",
            ratingV2: "-",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "grey",
              tint: "500",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹3,000 for two",
      },
      cfo: {
        text: "₹500 for one",
      },
      locality: {
        name: "Renaissance Lucknow Hotel, Lucknow",
        address:
          "Renaissance Lucknow Hotel, 16th Floor, Vipin Khand, Gomti Nagar, Lucknow",
        localityUrl: "lucknow/restaurants/in/renaissance-lucknow-hotel",
      },
      timing: {
        text: "Opens on Monday at 5pm",
        color: "#ab000d",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjcxXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/finger-food/",
          name: "Finger Food",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNDBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/fast-food/",
          name: "Fast Food",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjcwXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/beverages/",
          name: "Beverages",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹3,000 for two",
      },
    },
    order: [],
    gold: [],
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/sky-bar-renaissance-lucknow-hotel-gomti-nagar/info",
      clickActionDeeplink: "",
    },
    distance: "2092.7 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"18159999","element_type":"listing","rank":24}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [
      {
        type: "booking_cta",
        text: "Book a Table",
        clickUrl: "/lucknow/sky-bar-renaissance-lucknow-hotel-gomti-nagar/book",
      },
    ],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },

  {
    type: "restaurant",
    info: {
      resId: 18550429,
      name: "Grand Cabana - Hotel Savvy Grand",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/9/18550429/9c8fffbf4b060fc5d3d60f92e7b8fb26_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/9/18550429/9c8fffbf4b060fc5d3d60f92e7b8fb26_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: "https://b.zmtcdn.com/data/pictures/9/18550429/b22af80764e2f4e0d30c8703897ea2bb_o2_featured_v2.jpg",
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "4.2",
        rating_text: "4.2",
        rating_subtitle: "Very Good",
        rating_color: "5BA829",
        votes: "619",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "4.2",
            reviewCount: "565",
            reviewTextSmall: "565 Reviews",
            subtext: "565 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "4.2",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "700",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "4.2",
            reviewCount: "54",
            reviewTextSmall: "54 Reviews",
            subtext: "54 Delivery Reviews",
            color: "#E23744",
            ratingV2: "4.2",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "green",
              tint: "700",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹1,000 for two",
      },
      cfo: {
        text: "₹400 for one",
      },
      locality: {
        name: "Gomti Nagar, Lucknow",
        address: "11-A, Vibhuti Khand, Gomti Nagar, Lucknow",
        localityUrl: "lucknow/gomti-nagar-restaurants",
      },
      timing: {
        text: "Closes in 1 hour 26 minutes",
        color: "#e5521f",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/north-indian/",
          name: "North Indian",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹1,000 for two",
      },
    },
    order: [],
    gold: [],
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/grand-cabana-hotel-savvy-grand-gomti-nagar/info",
      clickActionDeeplink: "",
    },
    distance: "4.6 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"18550429","element_type":"listing","rank":25}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },
  {
    type: "restaurant",
    info: {
      resId: 18553847,
      name: "EDGE - Rooftop Bar & Lounge",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/7/18553847/056178323b64589ad21b995dbb153787_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/7/18553847/056178323b64589ad21b995dbb153787_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: false,
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "3.9",
        rating_text: "3.9",
        rating_subtitle: "Good",
        rating_color: "9ACD32",
        votes: "71",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "3.9",
            reviewCount: "71",
            reviewTextSmall: "71 Reviews",
            subtext: "71 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "3.9",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "600",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "",
            reviewCount: "0",
            reviewTextSmall: "0 Reviews",
            subtext: "Does not offer Delivery",
            color: "",
            ratingV2: "-",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "grey",
              tint: "500",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹400 for two",
      },
      cfo: {
        text: "₹400 for one",
      },
      locality: {
        name: "Novotel, Gomti Nagar, Lucknow",
        address: "CP-74,Viraj Khand, Gomti Nagar, Lucknow",
        localityUrl: "lucknow/restaurants/in/novotel-gomti-nagar",
      },
      timing: {
        text: "Closes in 27 minutes",
        color: "#ab000d",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjcxXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/finger-food/",
          name: "Finger Food",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹400 for two",
      },
    },
    order: [],
    gold: {
      text: "Pro - Get 20% off",
    },
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/edge-rooftop-bar-lounge-gomti-nagar/info",
      clickActionDeeplink: "",
    },
    distance: "7.4 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"18553847","element_type":"listing","rank":26}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [
      {
        type: "booking_cta",
        text: "Book a Table",
        clickUrl: "/lucknow/edge-rooftop-bar-lounge-gomti-nagar/book",
      },
    ],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },
  {
    type: "restaurant",
    info: {
      resId: 801195,
      name: "Cappuccino Blast",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/5/801195/c13c76338537c2ed3bfdf656aa0c7f43_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/5/801195/c13c76338537c2ed3bfdf656aa0c7f43_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: "https://b.zmtcdn.com/data/pictures/chains/6/800576/cb5ad4303e47f17072e9ee02fe8b6cbe_o2_featured_v2.jpg",
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "3.8",
        rating_text: "3.8",
        rating_subtitle: "Good",
        rating_color: "9ACD32",
        votes: "337",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "3.9",
            reviewCount: "260",
            reviewTextSmall: "260 Reviews",
            subtext: "260 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "3.9",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "600",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "3.6",
            reviewCount: "77",
            reviewTextSmall: "77 Reviews",
            subtext: "77 Delivery Reviews",
            color: "#E23744",
            ratingV2: "3.6",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "green",
              tint: "600",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹700 for two",
      },
      cfo: {
        text: "₹400 for one",
      },
      locality: {
        name: "Aishbagh, Lucknow",
        address: "73-A, Mill Road, Industrial Area, Aishbagh, Lucknow",
        localityUrl: "lucknow/aishbagh-restaurants",
      },
      timing: {
        text: "Closes in 27 minutes",
        color: "#ab000d",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/chinese/",
          name: "Chinese",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNDBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/fast-food/",
          name: "Fast Food",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/north-indian/",
          name: "North Indian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiODJcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/pizza/",
          name: "Pizza",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMTA2NFwiXX0iXQ%3D%3D",
          url: "https://www.zomato.com/lucknow/restaurants/pasta/",
          name: "Pasta",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjcwXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/beverages/",
          name: "Beverages",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMTAwXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/desserts/",
          name: "Desserts",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹700 for two",
      },
    },
    order: [],
    gold: [],
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/cappuccino-blast-aishbagh/info",
      clickActionDeeplink: "",
    },
    distance: "5.3 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"801195","element_type":"listing","rank":27}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [
      {
        type: "chain_cta",
        text: "View all outlets",
        clickUrl: "/lucknow/restaurants/cappuccino-blast?category=3",
      },
    ],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },
  {
    type: "restaurant",
    info: {
      resId: 19091558,
      name: "The Beer Cafe",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/8/19091558/3e8946bae5d6458ab9b07d4f2a1dc78a_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/8/19091558/3e8946bae5d6458ab9b07d4f2a1dc78a_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: "https://b.zmtcdn.com/data/pictures/8/19091558/1f5dd55550afa65f615128358140e155_o2_featured_v2.jpg",
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "3.7",
        rating_text: "3.7",
        rating_subtitle: "Good",
        rating_color: "9ACD32",
        votes: "42",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "3.7",
            reviewCount: "35",
            reviewTextSmall: "35 Reviews",
            subtext: "35 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "3.7",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "600",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "",
            reviewCount: "7",
            reviewTextSmall: "7 Reviews",
            subtext: "Does not offer Delivery",
            color: "",
            ratingV2: "-",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "grey",
              tint: "500",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹1,200 for two",
      },
      cfo: {
        text: "₹400 for one",
      },
      locality: {
        name: "Gomti Nagar, Lucknow",
        address:
          "005, Ground Floor, Rohtas Summit, Vibhuti Khand, Gomti Nagar, Lucknow, 226018",
        localityUrl: "lucknow/gomti-nagar-restaurants",
      },
      timing: {
        text: "Opens tomorrow at 12noon",
        color: "#ab000d",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjcxXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/finger-food/",
          name: "Finger Food",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/italian/",
          name: "Italian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/north-indian/",
          name: "North Indian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMzVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/continental/",
          name: "Continental",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMTA2NFwiXX0iXQ%3D%3D",
          url: "https://www.zomato.com/lucknow/restaurants/pasta/",
          name: "Pasta",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹1,200 for two",
      },
    },
    order: [],
    gold: [],
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/the-beer-cafe-gomti-nagar/info",
      clickActionDeeplink: "",
    },
    distance: "6.2 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"19091558","element_type":"listing","rank":28}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },
  {
    type: "restaurant",
    info: {
      resId: 800273,
      name: "Urban Terrace",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/chains/3/800273/a32b3b0726fa49b7dfd7ec4fbdd36ec4_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/chains/3/800273/a32b3b0726fa49b7dfd7ec4fbdd36ec4_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: "https://b.zmtcdn.com/data/pictures/chains/3/800273/91b4754c97c4f5ed1903ade4932b34bd_o2_featured_v2.jpg",
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "4.3",
        rating_text: "4.3",
        rating_subtitle: "Very Good",
        rating_color: "5BA829",
        votes: "806",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "4.2",
            reviewCount: "480",
            reviewTextSmall: "480 Reviews",
            subtext: "480 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "4.2",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "700",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "4.3",
            reviewCount: "326",
            reviewTextSmall: "326 Reviews",
            subtext: "326 Delivery Reviews",
            color: "#E23744",
            ratingV2: "4.3",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "green",
              tint: "700",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹1,100 for two",
      },
      cfo: {
        text: "₹400 for one",
      },
      locality: {
        name: "Lineage Hotels, Gomti Nagar, Lucknow",
        address:
          "Hotel Lineage CP-73, Viraj Khand,Gomtinagar,Near Sahara Hospital Gate No-2 Lucknow",
        localityUrl: "lucknow/restaurants/in/lineage-hotels-gomti-nagar",
      },
      timing: {
        text: "Closes in 27 minutes",
        color: "#ab000d",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNzVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/mughlai/",
          name: "Mughlai",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/chinese/",
          name: "Chinese",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/italian/",
          name: "Italian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMzVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/continental/",
          name: "Continental",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹1,100 for two",
      },
    },
    order: [],
    gold: [],
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/urban-terrace-gomti-nagar/info",
      clickActionDeeplink: "",
    },
    distance: "7.4 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"800273","element_type":"listing","rank":29}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },
  {
    type: "restaurant",
    info: {
      resId: 19007115,
      name: "The Vault Disc And Lounge",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/5/19007115/f6dc83bb34f957c23d4ab9886bf9c303_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/5/19007115/f6dc83bb34f957c23d4ab9886bf9c303_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: false,
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "3.5",
        rating_text: "3.5",
        rating_subtitle: "Good",
        rating_color: "9ACD32",
        votes: "89",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "3.5",
            reviewCount: "89",
            reviewTextSmall: "89 Reviews",
            subtext: "89 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "3.5",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "500",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "",
            reviewCount: "0",
            reviewTextSmall: "0 Reviews",
            subtext: "Does not offer Delivery",
            color: "",
            ratingV2: "-",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "grey",
              tint: "500",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹2,200 for two",
      },
      cfo: {
        text: "₹400 for one",
      },
      locality: {
        name: "Khurram Nagar, Lucknow",
        address:
          "Main Ring Road, Hotel Vijay Paradise, Vikas Nagar, Khurram Nagar, Lucknow",
        localityUrl: "lucknow/khurram-nagar-restaurants",
      },
      timing: {
        text: "Closes in 27 minutes",
        color: "#ab000d",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMzVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/continental/",
          name: "Continental",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/chinese/",
          name: "Chinese",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNDBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/fast-food/",
          name: "Fast Food",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjI3XCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/bar-food/",
          name: "Bar Food",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjcwXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/beverages/",
          name: "Beverages",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹2,200 for two",
      },
    },
    order: [],
    gold: [],
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/the-vault-disc-and-lounge-khurram-nagar/info",
      clickActionDeeplink: "",
    },
    distance: "4.2 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"19007115","element_type":"listing","rank":30}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [
      {
        type: "booking_cta",
        text: "Book a Table",
        clickUrl: "/lucknow/the-vault-disc-and-lounge-khurram-nagar/book",
      },
    ],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },
  {
    type: "restaurant",
    info: {
      resId: 801693,
      name: "Bar Bar - Hotel India Awadh",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/chains/3/801693/767aa3f776cd94b978227523eac17d59_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/chains/3/801693/767aa3f776cd94b978227523eac17d59_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: false,
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "4.2",
        rating_text: "4.2",
        rating_subtitle: "Very Good",
        rating_color: "5BA829",
        votes: "452",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "4.2",
            reviewCount: "452",
            reviewTextSmall: "452 Reviews",
            subtext: "452 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "4.2",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "700",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "",
            reviewCount: "0",
            reviewTextSmall: "0 Reviews",
            subtext: "Does not offer Delivery",
            color: "",
            ratingV2: "-",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "grey",
              tint: "500",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹1,500 for two",
      },
      cfo: {
        text: "₹400 for one",
      },
      locality: {
        name: "Hotel India Awadh, Hazratganj, Lucknow",
        address: "Hotel India Awadh, 4, Sapru Marg, Hazratganj, Lucknow",
        localityUrl: "lucknow/restaurants/in/hotel-india-awadh-hazratganj",
      },
      timing: {
        text: "Opens tomorrow at 10am",
        color: "#ab000d",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMzBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/cafes/",
          name: "Cafe",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/chinese/",
          name: "Chinese",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiOTVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/thai/",
          name: "Thai",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/north-indian/",
          name: "North Indian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMzVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/continental/",
          name: "Continental",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹1,500 for two",
      },
    },
    order: [],
    gold: [],
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/bar-bar-hotel-india-awadh-hazratganj/info",
      clickActionDeeplink: "",
    },
    distance: "1.3 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"801693","element_type":"listing","rank":31}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [
      {
        type: "booking_cta",
        text: "Book a Table",
        clickUrl: "/lucknow/bar-bar-hotel-india-awadh-hazratganj/book",
      },
    ],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },
  {
    type: "restaurant",
    info: {
      resId: 18610092,
      name: "Ttamaasha Bistro Bar",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/2/18610092/6a8b695bade8d45687cbd7e30ebb6145_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/2/18610092/6a8b695bade8d45687cbd7e30ebb6145_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: "https://b.zmtcdn.com/data/pictures/2/18610092/dc092e5921d077441c6dfcda635a4079_o2_featured_v2.jpeg",
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "4.1",
        rating_text: "4.1",
        rating_subtitle: "Very Good",
        rating_color: "5BA829",
        votes: "474",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "4.1",
            reviewCount: "474",
            reviewTextSmall: "474 Reviews",
            subtext: "474 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "4.1",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "700",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "",
            reviewCount: "0",
            reviewTextSmall: "0 Reviews",
            subtext: "Does not offer Delivery",
            color: "",
            ratingV2: "-",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "grey",
              tint: "500",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹2,200 for two",
      },
      cfo: {
        text: "₹400 for one",
      },
      locality: {
        name: "Gomti Nagar, Lucknow",
        address: "4th Floor, Wave Mall, Gomti Nagar, Lucknow",
        localityUrl: "lucknow/gomti-nagar-restaurants",
      },
      timing: {
        text: "Closes in 1 hour 26 minutes",
        color: "#e5521f",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/north-indian/",
          name: "North Indian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/italian/",
          name: "Italian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/chinese/",
          name: "Chinese",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNDBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/fast-food/",
          name: "Fast Food",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹2,200 for two",
      },
    },
    order: [],
    gold: {
      text: "Pro - Get 20% off",
    },
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/ttamaasha-bistro-bar-gomti-nagar/info",
      clickActionDeeplink: "",
    },
    distance: "4.6 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"18610092","element_type":"listing","rank":32}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },
  {
    type: "restaurant",
    info: {
      resId: 800603,
      name: "Theka - The Piccadily",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/chains/3/800603/3090e78a5858e43a2722566f975a9819_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/chains/3/800603/3090e78a5858e43a2722566f975a9819_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: "https://b.zmtcdn.com/data/pictures/3/800603/8144efc6b49496d055898f4f96aa44f9_o2_featured_v2.jpg",
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "4.0",
        rating_text: "4.0",
        rating_subtitle: "Very Good",
        rating_color: "5BA829",
        votes: "165",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "4.0",
            reviewCount: "165",
            reviewTextSmall: "165 Reviews",
            subtext: "165 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "4.0",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "600",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "",
            reviewCount: "0",
            reviewTextSmall: "0 Reviews",
            subtext: "Does not offer Delivery",
            color: "",
            ratingV2: "-",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "grey",
              tint: "500",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹2,700 for two",
      },
      cfo: {
        text: "₹400 for one",
      },
      locality: {
        name: "The Piccadily Lucknow, Alambagh, Lucknow",
        address:
          "The Piccadily Lucknow, Ground Floor, Bara Birva, Kanpur Road, Alambagh, Lucknow",
        localityUrl: "lucknow/restaurants/in/the-piccadily-lucknow-alambagh",
      },
      timing: {
        text: "Closes in 27 minutes",
        color: "#ab000d",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjcxXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/finger-food/",
          name: "Finger Food",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹2,700 for two",
      },
    },
    order: [],
    gold: {
      text: "Pro - Get 20% off",
    },
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/theka-piccadily-alambagh/info",
      clickActionDeeplink: "",
    },
    distance: "9.1 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"800603","element_type":"listing","rank":33}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [
      {
        type: "booking_cta",
        text: "Book a Table",
        clickUrl: "/lucknow/theka-piccadily-alambagh/book",
      },
    ],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },
  {
    type: "restaurant",
    info: {
      resId: 18574816,
      name: "Orchid - Fortune Park BBD",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/6/18574816/7b35c85922598324498c7bfe703258b1_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/6/18574816/7b35c85922598324498c7bfe703258b1_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: "https://b.zmtcdn.com/data/pictures/6/18574816/a2ad0976c5c49155cc10654d66be5349_o2_featured_v2.jpg",
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "3.9",
        rating_text: "3.9",
        rating_subtitle: "Good",
        rating_color: "9ACD32",
        votes: "194",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "3.9",
            reviewCount: "167",
            reviewTextSmall: "167 Reviews",
            subtext: "167 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "3.9",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "600",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "3.9",
            reviewCount: "27",
            reviewTextSmall: "27 Reviews",
            subtext: "27 Delivery Reviews",
            color: "#E23744",
            ratingV2: "3.9",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "green",
              tint: "600",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹1,700 for two",
      },
      cfo: {
        text: "₹400 for one",
      },
      locality: {
        name: "Fortune Park BBD, Lucknow",
        address: "Fortune Park BBD, Ranapratap Marg, Hazratganj, Lucknow",
        localityUrl: "lucknow/restaurants/in/fortune-park-bbd",
      },
      timing: {
        text: "Closes in 27 minutes",
        color: "#ab000d",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/north-indian/",
          name: "North Indian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMzVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/continental/",
          name: "Continental",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/chinese/",
          name: "Chinese",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNDBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/fast-food/",
          name: "Fast Food",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMTAwXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/desserts/",
          name: "Desserts",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjcwXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/beverages/",
          name: "Beverages",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹1,700 for two",
      },
    },
    order: [],
    gold: {
      text: "Pro - Get 25% off",
    },
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/orchid-fortune-park-bbd-hazratganj/info",
      clickActionDeeplink: "",
    },
    distance: "1.4 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"18574816","element_type":"listing","rank":34}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [
      {
        type: "booking_cta",
        text: "Book a Table",
        clickUrl: "/lucknow/orchid-fortune-park-bbd-hazratganj/book",
      },
    ],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },
  {
    type: "restaurant",
    info: {
      resId: 800932,
      name: "Cheer Peer - The Metropolitan Club",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/2/800932/c484ae4d2d9a5b339fbe67d668fe29a7_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/2/800932/c484ae4d2d9a5b339fbe67d668fe29a7_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: "https://b.zmtcdn.com/data/pictures/2/800932/3c9779b7dbfb3d962493230ecec11a8c_o2_featured_v2.jpg",
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "4.0",
        rating_text: "4.0",
        rating_subtitle: "Very Good",
        rating_color: "5BA829",
        votes: "237",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "4.0",
            reviewCount: "237",
            reviewTextSmall: "237 Reviews",
            subtext: "237 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "4.0",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "600",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "",
            reviewCount: "0",
            reviewTextSmall: "0 Reviews",
            subtext: "Does not offer Delivery",
            color: "",
            ratingV2: "-",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "grey",
              tint: "500",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹1,500 for two",
      },
      cfo: {
        text: "₹400 for one",
      },
      locality: {
        name: "Metropolitan Club, Gomti Nagar, Lucknow",
        address:
          "CP 134, Metropolitan Club, Viraj Khand, Near Sahara Hospital, Gomti Nagar, Lucknow",
        localityUrl: "lucknow/restaurants/in/metropolitan-clubs-gomti-nagar",
      },
      timing: {
        text: "Opens tomorrow at 11:30am",
        color: "#ab000d",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/north-indian/",
          name: "North Indian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNzVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/mughlai/",
          name: "Mughlai",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/chinese/",
          name: "Chinese",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjcwXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/beverages/",
          name: "Beverages",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹1,500 for two",
      },
    },
    order: [],
    gold: {
      text: "Pro - Get 20% off",
    },
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/cheer-peer-the-metropolitan-club-gomti-nagar/info",
      clickActionDeeplink: "",
    },
    distance: "7.6 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"800932","element_type":"listing","rank":35}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [
      {
        type: "booking_cta",
        text: "Book a Table",
        clickUrl: "/lucknow/cheer-peer-the-metropolitan-club-gomti-nagar/book",
      },
    ],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },
  {
    type: "restaurant",
    info: {
      resId: 800922,
      name: "EOS Bar & Bistro- Hotel Levana",
      image: {
        url: "https://b.zmtcdn.com/data/pictures/2/800922/6238f47ada44afffa00bd51a9830bb0d_featured_v2.jpg",
        urlWithParams:
          "https://b.zmtcdn.com/data/pictures/2/800922/6238f47ada44afffa00bd51a9830bb0d_featured_v2.jpg?fit=around%7C108%3A108&crop=108%3A108%3B%2A%2C%2A",
      },
      o2FeaturedImage: {
        url: "https://b.zmtcdn.com/data/pictures/2/800922/90b16ad2c6144901b58160494efe4850_o2_featured_v2.jpg",
      },
      rating: {
        has_fake_reviews: 0,
        aggregate_rating: "3.8",
        rating_text: "3.8",
        rating_subtitle: "Good",
        rating_color: "9ACD32",
        votes: "377",
        subtext: "REVIEWS",
        is_new: false,
      },
      ratingNew: {
        newlyOpenedObj: null,
        suspiciousReviewObj: null,
        ratings: {
          DINING: {
            rating_type: "DINING",
            rating: "4.1",
            reviewCount: "333",
            reviewTextSmall: "333 Reviews",
            subtext: "333 Dining Reviews",
            color: "#1C1C1C",
            ratingV2: "4.1",
            subtitle: "DINING",
            sideSubTitle: "Dining Reviews",
            bgColorV2: {
              type: "green",
              tint: "700",
            },
          },
          DELIVERY: {
            rating_type: "Coser",
            rating: "3.5",
            reviewCount: "44",
            reviewTextSmall: "44 Reviews",
            subtext: "44 Delivery Reviews",
            color: "#E23744",
            ratingV2: "3.5",
            subtitle: "Coser",
            sideSubTitle: "Delivery Reviews",
            bgColorV2: {
              type: "green",
              tint: "500",
            },
            newOnDelivery: false,
          },
        },
      },
      cft: {
        text: "₹2,000 for two",
      },
      cfo: {
        text: "₹400 for one",
      },
      locality: {
        name: "Hotel Levana, Hazratganj, Lucknow",
        address: "Hotel Levana, 3rd Floor, 72, MG Marg, Hazratganj, Lucknow",
        localityUrl: "lucknow/restaurants/in/hotel-levana-hazratganj",
      },
      timing: {
        text: "Temporarily closed, will be back soon!",
        color: "",
      },
      cuisine: [
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjcxXCJdfSJd",
          url: "https://www.zomato.com/lucknow/restaurants/finger-food/",
          name: "Finger Food",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMzVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/continental/",
          name: "Continental",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiNTBcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/north-indian/",
          name: "North Indian",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiMjVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/chinese/",
          name: "Chinese",
        },
        {
          deeplink:
            "zomato://search?deeplink_filters=WyJ7XCJjb250ZXh0XCI6XCJhbGxcIn0iLCJ7XCJjdWlzaW5lX2lkXCI6W1wiOTVcIl19Il0%3D",
          url: "https://www.zomato.com/lucknow/restaurants/thai/",
          name: "Thai",
        },
      ],
      should_ban_ugc: false,
      costText: {
        text: "₹2,000 for two",
      },
    },
    order: [],
    gold: [],
    takeaway: [],
    cardAction: {
      text: "",
      clickUrl: "/lucknow/eos-bar-bistro-hotel-levana-hazratganj/info",
      clickActionDeeplink: "",
    },
    distance: "1.9 km",
    isPromoted: false,
    promotedText: "",
    trackingData: [
      {
        table_name: "zsearch_events_log",
        payload:
          '{"search_id":"14afd6f5-bdc9-4f55-bac0-1515a91b62c0","location_type":"delivery_cell","location_id":"4151190155136335872","page_type":"nightlife","app_type":"new_web_consumer","section":"restaurants","entity_type":"restaurant","entity_id":"800922","element_type":"listing","rank":36}',
        event_names: {
          tap: '{"action":"tap"}',
          impression: '{"action":"impression"}',
        },
      },
    ],
    allCTA: [],
    promoOffer: "",
    checkBulkOffers: false,
    bulkOffers: [],
    isDisabled: false,
    bottomContainers: [],
  },
];
