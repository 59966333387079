import React, {useState} from "react";
import "./header.css";

import { atom, useAtom } from 'jotai';

export const searchValueAtom = atom('');
export const isSearchingAtom = atom(null);

export const SearchBox = () => {
  const [searchValue, setSearchValue] = useAtom(searchValueAtom);
  const [isSearching, setIsSearching] = useAtom(isSearchingAtom);

  const triggerRotate = () => {
    if(isSearching === true) {
      return;
    }
    setIsSearching(true);
  }

  return <div className="header-location-search-container">
    <div className="location-wrapper">
      <div className="location-icon-name">
        <i className="fi fi-rr-marker absolute-center location-icon"></i>
        <div>Việt Nam</div>
      </div>
      <i className="fi fi-rr-caret-down absolute-cente caret-down-icon"></i>
    </div>
    <div className="location-search-separator"></div>
    <div className="header-searchBar">
      <div onClick={() => triggerRotate()}>
        <i className={"fi fi-rr-refresh absolute-center search-icon " + (isSearching && "rotate")}></i>
      </div>
      
      <input
        className="search-input"
        placeholder="Search for restaurant, cuisine ..."
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
      />
      { searchValue!=='' && (
        <div onClick={() => setSearchValue('')} className="wrapper-cross-icon">
          <i className="fi fi-rr-cross absolute-center cross-icon"></i>
        </div>
      )}
      
    </div>
  </div>
}

const Header = () => {
  return (
    <div className="header max-width">
      <a href="/" className="header-link">
        <img
          src="/cosplay_fit.png"
          alt="Zomato-logo"
          className="header-logo"
        />
      </a>
      
      <div className="header-right">
        {/* <SearchBox /> */}

        {/* <div className="profile-wrapper">
          <img
            src="https://b.zmtcdn.com/images/user_avatars/mug_2x.png?fit=around%7C100%3A100&crop=100%3A100%3B%2A%2C%2A"
            className="header-profile-image"
            alt="Profile"
          />
          <span className="header-username">Shuvam</span>
          <i className="fi fi-rr-angle-small-down absolute-center profile-options-icon"></i>
        </div> */}
      </div>
    </div>
  );
};

export default Header;
